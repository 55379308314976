@import "/bower_components/bootstrap/dist/css/bootstrap.min.css";
@import "/bower_components/font-awesome/css/font-awesome.css";
@import "//cdnjs.cloudflare.com/ajax/libs/highlight.js/9.6.0/styles/github.min.css";
@font-face {
  font-family: 'FontAwesome';
  src: url("/bower_components/font-awesome/fonts/fontawesome-webfont.ttf");
}
html,
body {
  font-family: 'Open Sans', Helvetica, sans-serif;
  background: #f5f5f5;
}
img {
  height: auto;
  width: auto;
  max-height: 200px;
  max-width: 350px;
  border: 1px solid #ccc;
  padding: 15px;
  margin: 5px;
  transition: all 0.4s ease-in-out;
  background: #fff;
  z-index: 1000;
}
img.transition {
  transform: scale(1.8);
}
img.pull-right {
  margin-left: 20px;
}
pre {
  background-color: #f8f8f8;
  color: #fff;
  padding: 10px;
  border-radius: 6px;
  position: relative;
  margin: 10px 0 20px 0;
  overflow: visible;
}
pre code {
  display: block;
  position: relative;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  word-wrap: normal;
  white-space: pre;
  padding: 5px;
  visibility: hidden;
}
pre code span.pln {
  color: #fff;
}
table {
  border-collapse: collapse;
  width: 100%;
  margin: 0 0 20px 0;
}
table th {
  background-color: #f5f5f5;
  text-align: left;
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 700;
  padding: 4px 8px;
  border: #e0e0e0 1px solid;
}
table td {
  vertical-align: top;
  padding: 2px 8px;
  border: #e0e0e0 1px solid;
}
#wrapper {
  padding-left: 0;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}
#wrapper .toggled {
  padding-left: 200px;
}
#wrapper #sidebar-wrapper {
  width: 200px;
  left: 200px;
}
#github-fork-badge {
  padding-top: 20px;
  color: #aaa;
  line-height: 20px;
  font-size: 10px;
}
#sidebar-wrapper {
  position: fixed;
  left: 0px;
  width: 0;
  height: 100%;
  margin-left: -200px;
  overflow-y: auto;
  padding: 10px;
  background: #000;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
  z-index: 100;
}
#page-content-wrapper {
  width: 100%;
  background: #f5f5f5;
}
#page-content-wrapper .padded {
  padding-top: 100px;
}
.faded {
  opacity: 0.4;
}
#main-content {
  background: #fff;
  padding: 0 30px 30px;
  line-height: 170%;
  z-index: 99;
}
#main-content h1,
#main-content h2,
#main-content h3,
#main-content h4,
#main-content h5 {
  margin-top: 65px;
}
#main-content h1 {
  padding: 10px 0;
  margin-top: 20px;
}
#main-content h2 {
  padding: 5px 0;
}
#main-content h5 {
  font-weight: bold;
  font-size: 110%;
}
#main-content table {
  margin-top: 35px;
}
.main-logo {
  float: right;
  margin: 40px 0 10px 20px;
}
.full-height {
  position: fixed;
  height: 100%;
}
.margin-auto {
  margin: auto;
}
.clear {
  clear: both;
}
#to-top {
  position: fixed;
  right: 20px;
  bottom: 30px;
  width: 50px;
  height: 50px;
  z-index: 100;
  cursor: pointer;
}
#top-menu {
  background: #000;
  padding: 10px 30px;
  color: #fff;
  font-size: 24px;
  position: fixed;
  left: 0px;
  right: 0px;
  width: 100%;
  height: 50px;
  z-index: 100;
}
#top-menu span {
  cursor: pointer;
}
ul.sidebar-nav {
  margin: 30px 0px 5px 0;
  padding: 0;
  list-style: none;
}
ul.sidebar-nav ul {
  list-style: none;
  padding: 0;
}
ul.sidebar-nav ul li {
  margin: 0;
}
ul.sidebar-nav ul li a {
  text-transform: none;
  font-weight: normal;
  border-color: #e5e5e5 !important;
}
ul.sidebar-nav li {
  line-height: 32px;
  margin-top: 15px;
}
ul.sidebar-nav li.small-margin {
  margin-top: 5px;
}
ul.sidebar-nav li.border-top {
  border-top: 1px solid #ccc;
  margin-top: 25px;
}
ul.sidebar-nav li span {
  font-size: 11px;
  font-weight: 600;
  text-transform: uppercase;
  color: #999;
}
ul.sidebar-nav li a {
  display: block;
  text-decoration: none;
  text-transform: uppercase;
  font-size: 80%;
  color: #aeaeae;
  padding-left: 10px;
  background: #232323;
  border-left: 5px solid;
  font-weight: 600;
}
ul.sidebar-nav li a.padded-left {
  padding-left: 20px;
}
ul.sidebar-nav li a.selected,
ul.sidebar-nav li a.active {
  color: #fff !important;
}
ul.sidebar-nav li a.alpha {
  border-color: #d8225e;
}
ul.sidebar-nav li a.alpha.selected,
ul.sidebar-nav li a.alpha.active {
  background: #d8225e !important;
}
ul.sidebar-nav li a.beta {
  border-color: #bf3287;
}
ul.sidebar-nav li a.beta.selected,
ul.sidebar-nav li a.beta.active {
  background: #bf3287 !important;
}
ul.sidebar-nav li a.gamma {
  border-color: #a63d9c;
}
ul.sidebar-nav li a.gamma.selected,
ul.sidebar-nav li a.gamma.active {
  background: #a63d9c !important;
}
ul.sidebar-nav li a.delta {
  border-color: #7f4eaf;
}
ul.sidebar-nav li a.delta.selected,
ul.sidebar-nav li a.delta.active {
  background: #7f4eaf !important;
}
ul.sidebar-nav li a.yotta {
  border-color: $yotta-color;
}
ul.sidebar-nav li a.yotta.selected,
ul.sidebar-nav li a.yotta.active {
  background: $yotta-color !important;
}
ul.sidebar-nav li a.epsilon {
  border-color: #685bba;
}
ul.sidebar-nav li a.epsilon.selected,
ul.sidebar-nav li a.epsilon.active {
  background: #685bba !important;
}
ul.sidebar-nav li a.zeta {
  border-color: #546cc4;
}
ul.sidebar-nav li a.zeta.selected,
ul.sidebar-nav li a.zeta.active {
  background: #546cc4 !important;
}
ul.sidebar-nav li a.eta {
  border-color: #3988d4;
}
ul.sidebar-nav li a.eta.selected,
ul.sidebar-nav li a.eta.active {
  background: #3988d4 !important;
}
ul.sidebar-nav li a.theta {
  border-color: #3bbfee;
}
ul.sidebar-nav li a.theta.selected,
ul.sidebar-nav li a.theta.active {
  background: #3bbfee !important;
}
ul.sidebar-nav li a.ioda {
  border-color: #00a4b3;
}
ul.sidebar-nav li a.ioda.selected,
ul.sidebar-nav li a.ioda.active {
  background: #00a4b3 !important;
}
ul.sidebar-nav li a.kappa {
  border-color: #00a98f;
}
ul.sidebar-nav li a.kappa.selected,
ul.sidebar-nav li a.kappa.active {
  background: #00a98f !important;
}
ul.sidebar-nav li a:hover {
  text-decoration: none;
  color: #fff;
  background: #404040;
}
ul.sidebar-nav li a:active,
ul.sidebar-nav li a:focus {
  text-decoration: none;
}
ul.sidebar-nav.second li a {
  color: #6f6f6f;
  background: #f5f5f5;
}
ul.sidebar-nav.second li a.alpha:hover {
  color: #d8225e;
}
ul.sidebar-nav.second li a.beta:hover {
  color: #bf3287;
}
ul.sidebar-nav.second li a.gamma:hover {
  color: #a63d9c;
}
ul.sidebar-nav.second li a.delta:hover {
  color: #7f4eaf;
}
ul.sidebar-nav.second li a.yotta:hover {
  color: $yotta-color;
}
ul.sidebar-nav.second li a.epsilon:hover {
  color: #685bba;
}
ul.sidebar-nav.second li a.zeta:hover {
  color: #546cc4;
}
ul.sidebar-nav.second li a.eta:hover {
  color: #3988d4;
}
ul.sidebar-nav.second li a.theta:hover {
  color: #3bbfee;
}
ul.sidebar-nav.second li a.ioda:hover {
  color: #00a4b3;
}
ul.sidebar-nav.second li a.kappa:hover {
  color: #00a98f;
}
ul.sidebar-nav.second li a:hover {
  background: #fff;
}
.code-overlay {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 10;
  background-color: rgba(248,248,248,0.9);
  padding: 15px 0 0 15px;
}
.code-overlay .login-button {
  color: #333;
}
.code-overlay .login-button span {
  text-decoration: underline;
  cursor: pointer;
}
@media (max-width: 767px) {
  #wrapper.toggled #sidebar-wrapper {
    width: 200px;
    left: 200px;
  }
  #wrapper.toggled #page-content-wrapper {
    margin-right: -200px;
  }
  #wrapper {
    padding-left: 0;
  }
  #wrapper #sidebar-wrapper {
    width: 0;
    left: 0;
    margin-top: 50px;
  }
  #wrapper #page-content-wrapper {
    padding-top: 50px;
    position: absolute;
    left: 0;
    margin-right: 0;
  }
}
@media (min-width: 768px) {
  #wrapper {
    padding-left: 200px;
  }
  #sidebar-wrapper {
    width: 200px;
    left: 200px;
  }
  #to-top,
  #top-menu {
    display: none;
  }
}
